body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

#holiday-add-button {
  align-self: flex-end;
}

.work-day-label {
  justify-self: left;
}

.work-day {
  align-self: center;
}

.grid-centerer {
  justify-content: center;
  align-items: center;
}

#add-exception-card {
  overflow: visible;
}

#client-page-root {
  min-height: 50%;
  min-width: 60vw;
  max-width: 100%;
  flex-flow: column wrap;
  place-items: center;
  gap: 0 .5rem;
  margin: 1rem;
  display: flex;
}

.mantine-Stepper-content {
  min-height: 60vh;
  min-width: 25rem;
  max-height: 100%;
  border-color: #d3d3d3;
  border-top-style: solid;
  border-top-width: medium;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

#add-exception-controls-container {
  grid-template-rows: repeat(5, max-content);
  gap: .5rem;
  display: grid;
}

#add-exception-container {
  min-width: min(60ch, 100%);
  grid-template-rows: max-content max-content;
  align-self: baseline;
  gap: 1rem;
  display: grid;
}

#standard-work-hours-container {
  grid-template-columns: max(60%, 20rem);
  gap: .2rem 1rem;
  display: grid;
}

.text-align-center {
  text-align: center !important;
}

.standard-grid-container {
  min-width: min(60ch, 100%);
  gap: 1rem;
  display: grid;
}

#final-page-container {
  text-align: center;
  font-size: larger;
}

.responsive-width {
  min-width: min(50ch, 100%);
}

#review-page-container {
  min-width: min(60ch, 100%);
  grid-template-rows: min-content min-content min-content;
  gap: 1rem;
  display: grid;
}

.card-section-title-text {
  border-color: #d3d3d3;
  border-bottom-style: solid;
  border-bottom-width: medium;
  margin-bottom: .5rem;
}

.day-start-end-container {
  grid-template-columns: 8rem 7rem 7rem;
  gap: .2rem 1rem;
}

@media screen and (max-width: 800px) {
  #client-page-root .mantine-Stepper-content {
    min-height: 50vh;
  }

  .grid-centerer {
    justify-content: left;
  }
}

.exception-start-end-container {
  grid-template-columns: auto auto;
}

#day-work-hours-container {
  gap: 1rem;
  display: grid;
}

.mantine-Tooltip-tooltip, .mantine-Tooltip-arrow {
  font-size: .9rem;
  font-weight: bold;
}

/*# sourceMappingURL=index.css.map */
