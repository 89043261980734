#holiday-add-button {
  align-self: flex-end;
}
.work-day-label {
  justify-self: left;
}
.work-day {
  align-self: center;
}
.grid-centerer {
  justify-content: center;
  align-items: center;
}

#add-exception-card {
  overflow: visible;
}

/* Containers */
/* FIXME standardize centering to a class */
#client-page-root {
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 0.5rem;
  margin: 1rem;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 50%;
  min-width: 60vw;
  max-width: 100%;
}
.mantine-Stepper-content {
  border-top: solid;
  border-color: lightgrey;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  min-width: 25rem;
  max-height: 100%;
}
#add-exception-controls-container {
  display: grid;
  grid-template-rows: repeat(5, max-content);
  gap: 0.5rem;
}
#add-exception-container {
  min-width: min(60ch, 100%);
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 1rem;
  align-self: baseline;
}

#standard-work-hours-container {
  display: grid;
  grid-template-columns: max(60%, 20rem);
  gap: 0.2rem 1rem;
}
/* Important used to overide mantine left align behavior */
.text-align-center {
  text-align: center !important;
}
.standard-grid-container {
  display: grid;
  min-width: min(60ch, 100%);
  gap: 1rem;
}


#final-page-container {
  font-size: larger;
  text-align: center;
}
.responsive-width {
  min-width: min(50ch,100%);
}
#review-page-container {
  display: grid;
  grid-template-rows: min-content min-content min-content;
  gap: 1rem;
  min-width: min(60ch,100%);
}

.card-section-title-text {
  border-bottom: solid;
  border-color: lightgrey;
  margin-bottom: 0.5rem;
}

.day-start-end-container {
  grid-template-columns: 8rem 7rem 7rem;
  gap: 0.2rem 1rem;
}
@media screen and (max-width: 800px) {

  #client-page-root .mantine-Stepper-content {
    min-height: 50vh;
  }
  .grid-centerer {
    justify-content: left;
  }
}
.exception-start-end-container {
  grid-template-columns: auto auto;
}
#day-work-hours-container {
  display: grid;
  gap: 1rem 1rem;
}

.mantine-Tooltip-tooltip,
.mantine-Tooltip-arrow {
  font-weight: bold;
  font-size: 0.9rem;
}

